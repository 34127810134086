<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <div class="item_cont">
      <div class="item_item">
        <div v-for="(item,index) in arrList" :key="index" class="item_bg" @click="ServersDetails(item)">
          <img v-lazy="`${item.coverImg}`" alt="" lazy class="imgss" />
          <!-- <div class="item_title">
            {{ item.title}}
          </div>
          <div class="item_bottom">了解更多</div> -->
        </div>
      </div>
      <div style="text-align: center;margin:20px;">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNum" :page-size="pageSize" layout=" prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import { serversList } from '../api/index'
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";

export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data () {
    return {
      h1: "服务案例",
      p1: "",
     
      Img1: require("../assets/img/banner1.png"),
      arrList: [],
      total: null,
      pageSize: 10,
      pageNum: null,
    };
  },
  mounted () {
    this.getServersList()
  },
  methods: {
    ServersDetails (val) {
      this.$router.push({ path: `/productDetail/${val.id}` });
    },
    getServersList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      serversList(params).then((res) => {
        this.arrList = res.rows
        this.total = res.total
      })
    },
    handleCurrentChange () {
      this.getServersList()
    }

  },
};
</script>
<style scoped>
/* 新闻列表 */
.item_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.item_item {
  display: flex;
  flex-wrap: wrap;
}

.item_bg {
  /* border: 1px solid #afafaf; */
  cursor: pointer;
  padding-bottom: 20px;
  margin: 0 20px 40px 0;
}
.imgss {
  margin: 20px;
  width: 300px;
  height: 188px;
}
.item_title {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
}
.item_bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: rgb(87, 87, 225);
}
</style>


